exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-desarrollo-web-jsx": () => import("./../../../src/pages/desarrollo-web.jsx" /* webpackChunkName: "component---src-pages-desarrollo-web-jsx" */),
  "component---src-pages-equipo-jsx": () => import("./../../../src/pages/equipo.jsx" /* webpackChunkName: "component---src-pages-equipo-jsx" */),
  "component---src-pages-hospedaje-jsx": () => import("./../../../src/pages/hospedaje.jsx" /* webpackChunkName: "component---src-pages-hospedaje-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-marketing-digital-jsx": () => import("./../../../src/pages/marketing-digital.jsx" /* webpackChunkName: "component---src-pages-marketing-digital-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-clients-jsx": () => import("./../../../src/templates/clients.jsx" /* webpackChunkName: "component---src-templates-clients-jsx" */)
}

